/*
    Endeca control class for the typeahead endeca instance.
*/
var global = global || {};
var site = site || {};
site.endeca = site.endeca || {};
site.endeca.instances = site.endeca.instances || {};
site.endeca.instances.typeahead = site.endeca.instances.typeahead || {};

site.endeca.instances.typeahead.control = site.endeca.generic.Class.create(site.endeca.control, {
    initialize: function (args) {
        this.timer = 0;
        this._super(args);

        if (this.nodes.wrapper.length && this.nodes.inputElements.length) {
            var that = this;
            this.nodes.inputElements.on('keyup', that, that.onKeyUp);
            this._watchToClose();
        }
    },

    onKeyUp: function (event) {
        var $this = jQuery(this);
        var that = event.data;
        clearTimeout(that.timer);
        that.timer = setTimeout(function () {
            that.currentInputElement = $this;
            var searchTerm = jQuery.trim( $this.val() );
            if (searchTerm != that.searchTerm && searchTerm.length >= that.configuration.minSearchLength) {
                searchTerm = searchTerm + "*";
                that.searchTerm = searchTerm;
                that.search({
                    searchTerm: searchTerm
                });
            }
        }, 50);
    },

    _watchToClose: function () {
        var that = this;
        jQuery(document).on('click', that, function (event) {
            var tgt = event.target;
            var that = event.data;

            if (!jQuery(tgt).parents(that.nodes.wrapper.selector).length &&
                tgt != that.currentInputElement &&
                tgt != that.nodes.wrapper[0]) {
                that.nodes.wrapper.addClass('hidden');
                return;
            }
        });
    },

    searchCompleted: function () {
        if (!this._super()) {
            return;
        }

        if (this.queries?.product?.jsonResult?.AggrRecords?.length) {
            var termResultDataRef = {};
            var termResultData = [];

            for ( var i = 0; i < this.queries.product.jsonResult.AggrRecords.length; i++ ) {
                if ( this.queries.product.jsonResult.AggrRecords[i].Properties["DGraph.WhyDidItMatch"] ) {
                    var matches;

                    if ( typeof this.queries.product.jsonResult.AggrRecords[i].Properties["DGraph.WhyDidItMatch"] == "string" ) {
                        matches = [ this.queries.product.jsonResult.AggrRecords[i].Properties["DGraph.WhyDidItMatch"] ]
                    } else {
                        matches = this.queries.product.jsonResult.AggrRecords[i].Properties["DGraph.WhyDidItMatch"]
                    }

                    for ( var j = 0; j < matches.length; j++ ) {
                        if ( matches[j] && matches[j].match(/Product Category: (.*)/)) {
                            if ( ! RegExp.$1.match(/^CAT/) ) {
                                if ( termResultDataRef[ RegExp.$1 ] ) {
                                    termResultDataRef[ RegExp.$1 ]++;
                                } else {
                                    termResultDataRef[ RegExp.$1 ] = 1;
                                    termResultData.push({
                                        'term': RegExp.$1,
                                        'url': this.configuration.fullSearchBaseUrl + encodeURIComponent(RegExp.$1)
                                    });
                                }
                            }
                        }
                    }
                }
            }


            if ( this.results.terms.configuration.limit && termResultData.length > this.results.terms.configuration.limit ) {
                termResultData.splice( this.results.terms.configuration.limit );
            }

            termResultData.sort( function( a, b ) {
                if ( termResultDataRef[a] > termResultDataRef[b] ) { return -1; }
                   else if ( termResultDataRef[a] < termResultDataRef[b] ) { return 1; }
                return 0;
            });


            this.results.terms.resultData = termResultData;
            this.results.terms.displayResults();
            this.results.terms.displayResultNodes();
            this.results.terms.show();
            this.hasResults = true;
        } else {
            this.results.terms.hide();
        }


        this.results.products.resultData = this.catalogs.product.resultList;

        if (this.results.products.resultData.length) {
            this.results.products.displayResults();
            this.results.products.show();
            this.hasResults = true;
        } else {
            this.results.products.hide();
        }


        if ( this.hasResults ) {
            /* Add first search result */
            var searchTerm = this.searchTerm.replace('*', '');
            var showResults = rb.endeca["show_results"];
            showResults = showResults.replace('::term::', searchTerm);
            this.results.seeResults.resultData = [{
                'term': '<span class="search-count">' + this.meta.searchInfo.totalRecords + '</span><span class="search-results">' + showResults + '</span>',
                'url': this.configuration.fullSearchBaseUrl + searchTerm
            }];

            this.results.seeResults.displayResults();
            this.results.seeResults.displayResultNodes();
            this.results.seeResults.show();
        }

        this.nodes.wrapper.removeClass('hidden');
        var me = this.nodes;
        this.nodes.wrapper.find('.result').on('click', function (e) {
            var thisLink = this.getElementsByClassName('link');
            thisLink[0].trigger('click');
            //e.preventDefault();
            if (this.parentElement.parentNode.className = "term-results") {
                //    site.endeca.coremetrics.termClick();
            } else {
                site.endeca.coremetrics.productClick();
            }
            return true;
        });

        this.hideLoading();
    }
});
